import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Faq from './Faq'

interface IProps {
  faqData: {
    expansionFaq: string;
    result: {
      question: string;
      answer: string;
    }[]
  },
  category: string;
}

const HomeFaq = ({ faqData, category }: IProps) => (
    <div className="home-faq">
      <h2 className="fw-bold text-center mb-4 mt-4"><span className="zx-text-color-yellow">FAQ</span> - Frequently Asked Questions about {faqData?.expansionFaq} {category}</h2>
      <Row>
        {faqData?.result.map((question, index) => (
          <Col xs={12} md={6} key={index}>
            <Faq title={question.question} description={question.answer} />
          </Col>
        ))}
      </Row>
    </div>
)
export default HomeFaq
