import React from 'react'
import { Row, Col } from 'react-bootstrap'

interface IProps {
  title: string
  description: string
  miniDescription: string
}

const ExpansionInfo = ({ title, description, miniDescription }: IProps) => (
  <>
    <Row className="home-listing-title_content text-md-left text-xs-center">
      <Col className="mb-1" md={12}>
        <h2 className="home-listing-title_content_title">{title}</h2>
      </Col>
      <Col md={12}>
        <p className="home-listing-title_content_text">
          {description}
          <br/>
          {miniDescription}
          <br/>
          <span> Also, join our <a href="https://discord.gg/S6yffquAkA" target="_blank" rel="noopener noreferrer">Discord</a>! </span>
        </p>
      </Col>
    </Row>
  </>
)

export default ExpansionInfo
