import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Icon } from '@iconify/react'
import Link from 'next/link'

interface IProps {
  totalPages: number
  query: any
  currentPage: number,
  internalUrl?: string,
  hash?: string
}

const Pagination = ({ totalPages, query, currentPage, internalUrl, hash }: IProps) => {
  const addZeroPadding = (number: number) => {
    return number < 10 ? `0${number}` : number
  }

  const linkProps = (page: number) => {
    const queryCopy = query
    if (internalUrl) {
      // Remove internal and expansion from query
      delete queryCopy.internal

      if (queryCopy.home) {
        delete queryCopy.home
      }
    }

    queryCopy.page = page

    return {
      pathname: internalUrl || '',
      query: {
        ...queryCopy
      },
      hash
    }
  }
  const renderPaginate = (page: number) => {
    return (
      (<Link
        href={linkProps(page + 1)}
        key={page}
        className={
          'pagination_content_items_item btn btn-primary' +
          ((page + 1) === currentPage ? ' pagination_content_items_item-active' : '')
        }>

        { addZeroPadding(page + 1) }

      </Link>)
    )
  }

  const renderPage = (page: number) => {
    // Only show 5 pages at a time
    // if more than 5 pages, then show the previous 2 pages and the next 2 pages
    // if the current page is less than 3, then show the first 5 pages
    // if the current page is greater than the total pages - 3, then show the last 5 pages
    if (totalPages > 5) {
      if (currentPage < 3) {
        if (page < 5) {
          return renderPaginate(page)
        }
      } else if (currentPage > (totalPages - 3)) {
        if (page > (totalPages - 5)) {
          return renderPaginate(page)
        }
      } else {
        if (page > (currentPage - 4) && page < (currentPage + 2)) {
          return renderPaginate(page)
        }
      }
    } else {
      return renderPaginate(page)
    }
  }

  return <>
  { totalPages > 0 && (
    <div className="pagination">
      <Row>
        <Col className="mb-4" md={12}>
          <div className="pagination_content">
            <div className="me-3">
              <Link
                href={linkProps(currentPage - 1 > 0 ? (currentPage - 1) : 1)}
                legacyBehavior><i className=""><Icon className="pagination_content_arrow" icon="bytesize:arrow-left"/></i></Link>
            </div>
            <div className="pagination_content_items me-3">
              { Array.from(Array(totalPages).keys()).map((page) => renderPage(page)) }
            </div>
            <div>
              <Link
                href={linkProps(currentPage < totalPages ? currentPage + 1 : totalPages)}
                legacyBehavior><i className=""><Icon className="pagination_content_arrow" icon="bytesize:arrow-right"/></i></Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )}
  </>
}

export default Pagination
