import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { Icon } from '@iconify/react'

interface IProps {
  title: string
  description: string
}

const HomeFaq = ({ title, description }: IProps) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <div className="faq">
        <div className="faq_card">
          <div className="content-wrapper">
            <Col className="" md={12} onClick={handleClick}>
              <button type="button" className="ps-4 d-flex faq_btn collapsed w-100 justify-content-between align-items-baseline">
                <h2 className={'faq_title fw-bold fs-5 mb-0 ' + (open ? 'faq_title-highlight' : '')} >{ title } </h2>
                <div>
                  <i className="plus"><Icon className="svg-icon fs-4 ms-4 me-3" icon={ open ? 'akar-icons:minus' : 'akar-icons:plus'} /></i>
                </div>
              </button>
            </Col>
            <Col className={'ps-4 ' + (!open ? 'd-none' : '')} md={12}>
              <div className="faq_body">
                { description }
              </div>
            </Col>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeFaq
