import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

interface IProps {
  children: any
  loading?: boolean,
  disable?: boolean,
}

const BaseButton = ({ children, loading, disable, ...props } : IProps | any) => (
  <Button {...props} disabled={loading || disable}>
    { loading ? <Spinner animation="border" /> : children }
  </Button>
)

export default BaseButton
