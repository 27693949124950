import React from 'react'
import { Row, Col } from 'react-bootstrap'

interface IProps {
  title: string
  description: string
}

const GeneralInfo = ({ title, description }: IProps) => (
  <>
    <Row className="home-listing-title_content text-md-left text-xs-center">
      <Col className="mb-1" md={12}>
        <h2 className="home-listing-title_content_title">{title}</h2>
      </Col>
      <Col md={12}>
        <p className="home-listing-title_content_text mb-1">
          {description}</p>
        <p className="home-listing-title_content_text mb-0">
          Make sure to join our <a href="https://discord.gg/S6yffquAkA" target="_blank" rel="noopener noreferrer">Discord</a> to stay updated and socialize with our community.
        </p>
      </Col>
    </Row>
  </>
)

export default GeneralInfo
