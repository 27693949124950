import React from 'react'
import Image from 'next/image'

interface IGeneralNestedLayoutProps {
  children: React.ReactNode;
}

const GeneralNestedLayout = ({ children } : IGeneralNestedLayoutProps) => (
  <>
    <div className="general-layout_nested">
      <Image
        layout="fill"
        objectFit="cover"
        alt="Background"
        src={require('../../public/images/general-background.png')}
        style={{
          opacity: 0.6
        }}
        priority
      />
      <div className="general-layout_nested_background">
        <div className="general-layout_nested_planet-right">
          <Image width={174} height={303} src={require('../../public/images/planet-right.png')} placeholder='blur' alt="Planet 1" />
        </div>
        <div className="general-layout_nested_planet-left">
          <Image width={154} height={302} src={require('../../public/images/planet-left.png')} placeholder='blur' alt="Planet 2" />
        </div>
        <div className="general-layout_nested_flare-1">
          <Image width={111} height={111} src={require('../../public/images/flare-1.png')} placeholder='blur' alt="Flare 1" />
        </div>
        <div className="general-layout_nested_planet-bottom">
          <Image width={174} height={303} src={require('../../public/images/planet-right.png')} placeholder='blur' alt="Planet 3" />
        </div>
        <div className="general-layout_nested_flare-2">
          <Image width={88} height={111}src={require('../../public/images/flare-2.png')} placeholder='blur' alt="Flare 2" />
        </div>
      </div>
      <div className="general-layout_nested_content">
        {children}
      </div>
    </div>
  </>
)

export default GeneralNestedLayout
